import React, {useState} from 'react';
import Loader from "../components/Loader";
import Breadcrumbs from "../components/breadcrumbs";
import Banner from "./banner";
import Layout from "../layout/layout";
import Profile from "./profile";

function Index(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Breadcrumbs link1="TekRevol" link2="Case Studies"/>
                    <Banner/>
                    <Profile/>

                </>
            }
        </Layout>
    );
}

export default Index;